function openHelpcenterWidget() {
    FreshworksWidget('boot');
    FreshworksWidget('open');

    // Para que al pulsar en la X del widget no solo lo cierre sino que lo oculte
    setTimeout(() => {
        document.querySelector("#freshworks-frame-wrapper").addEventListener("DOMNodeRemoved", function () { FreshworksWidget('destroy'); })
    }, 0);
}
function closeHelpcenterWidget() {
    FreshworksWidget('close');
    // El destroy no lo lanzo aquí porque se hará con el evento DOMNodeRemoved que definimos al crear el widget
}